var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { visible: _vm.visible, title: this.$tc(_vm.title) },
      on: { cancel: _vm.handleModalCancel }
    },
    [
      _c(
        "a-spin",
        {
          attrs: { spinning: _vm.uploadFileLoading, tip: _vm.$t("Loading...") }
        },
        [
          _c(
            "div",
            { staticClass: "spin-content" },
            [
              _c(
                "a-upload-dragger",
                {
                  attrs: {
                    name: "files",
                    accept: ".json",
                    "show-upload-list": false,
                    "custom-request": _vm.handleUploadFile,
                    "before-upload": _vm.beforeUpload
                  }
                },
                [
                  _c("div", { staticClass: "upload-container" }, [
                    _c(
                      "p",
                      { staticClass: "ant-upload-drag-icon upload-icon" },
                      [_c("a-icon", { attrs: { type: "inbox" } })],
                      1
                    ),
                    _c("p", { staticClass: "ant-upload-text upload-text" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "Click or drag file TYPES to this area to upload",
                              { types: "(.json)" }
                            )
                          ) +
                          " "
                      )
                    ])
                  ])
                ]
              )
            ],
            1
          )
        ]
      ),
      _vm.alert.ragt.show
        ? _c("a-alert", {
            attrs: {
              message: _vm.alert.ragt.text,
              type: "warning",
              "show-icon": true,
              closable: false,
              banner: true
            }
          })
        : _vm._e(),
      _vm.importedUrl
        ? _c("div", { staticClass: "mt-sm mb-none" }, [
            _c("span", { staticClass: "text-bold" }, [
              _vm._v(_vm._s(_vm.$t("File name")) + ": ")
            ]),
            _c("span", [_vm._v(_vm._s(_vm.importedFileName))])
          ])
        : _vm._e(),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "a-button",
            { key: "back", on: { click: _vm.handleModalCancel } },
            [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]
          ),
          _c(
            "a-button",
            {
              key: "submit",
              attrs: {
                type: "primary",
                disabled: !_vm.importedUrl,
                loading: _vm.importLoading
              },
              on: { click: _vm.handleImporting }
            },
            [_vm._v(" " + _vm._s(_vm.$t("Import")) + " ")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }