//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { uploadFileToS3 } from '@/api/s3';
import { mapGetters } from 'vuex';
export default {
  props: {
    visible: { type: Boolean, default: false },
    title: { type: String, default: 'Import project' },
    type: { type: String, default: 'project' }
  },

  data() {
    return {
      importedUrl: '',
      importedFileName: '',
      uploadFileLoading: false,
      importLoading: false,
      fileData: null,
      alert: {
        ragt: {
          show: false,
          text: ''
        }
      }
    };
  },
  computed: {
    ...mapGetters('user', ['isSupportedRagt']),
    projectId() {
      return this.$route.params.projectId;
    }
  },
  watch: {
    fileData: {
      handler(value) {
        if (value) {
          // If not supported ragt, check if ragt imported exist
          if (!this.isSupportedRagt) {
            const exist = this.checkIfRagtImportedExist();
            if (exist) {
              this.alert.ragt.show = true;
              this.alert.ragt.text = this.$t(
                'You are trying to import a project or page that contains web content (HTML), but your account does not currently support the web content version of the voice code. Only plain text data will be imported.'
              );
            } else {
              this.alert.ragt.show = false;
              this.alert.ragt.text = '';
            }
          }
        }
        return '';
      },
      immediate: true
    }
  },
  methods: {
    async handleUploadFile({ file }) {
      try {
        this.uploadFileLoading = true;
        const key = `univoice/temps/${new Date().getTime()}.json`;
        this.importedUrl = await uploadFileToS3({ key, contentType: file.type, file });
        this.importedFileName = file.name;
        const fileArrayBuffer = await file.arrayBuffer();
        this.fileData = JSON.parse(new TextDecoder('utf-8').decode(fileArrayBuffer));
      } catch (error) {
        console.log(error);
        this.$notification.error({
          message: this.$t('You cannot upload this file.')
        });
      } finally {
        this.uploadFileLoading = false;
      }
    },
    beforeUpload(file) {
      const fileExtend = file.name.split('.').pop();
      if (!['json'].includes(fileExtend)) {
        this.$notification.warning({
          message: this.$t('Click or drag file TYPES to this area to upload', { types: '(.json)' })
        });
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 50;
      if (!isLt2M) {
        this.$notification.warning({
          message: this.$t('File must smaller than 50MB')
        });
      }
      return isLt2M;
    },
    handleModalCancel() {
      this.$emit('cancel');
    },
    handleImporting() {
      new Promise((resolve, reject) => {
        this.importLoading = true;
        const payload = {
          projectId: this.projectId,
          type: this.type,
          url: this.importedUrl,
          resolve,
          reject
        };
        this.$emit('ok', payload);
      })
        .then(() => {
          console.log('Importing success!');
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.importLoading = false;
        });
    },
    checkIfRagtImportedExist() {
      let exist = false;
      const recursiveFunc = data => {
        const { name, values } = data;
        if (exist) return exist;
        if (!name || !values || !Array.isArray(values)) throw new Error('Invalid ragt file format');
        if (!values.length) return false;
        if (name === 'code') {
          for (let i = 0; i < values.length; i++) {
            const code = values[i];
            for (let j = 0; j < code.codes.length; j++) {
              // check if code.codes[j].ragt object is not empty
              if (code.codes[j].ragt && Object.keys(code.codes[j].ragt).length) {
                exist = true;
                return exist;
              }
            }
          }
        } else {
          for (let i = 0; i < values.length; i++) {
            for (let j = 0; j < values[i].relations.length; j++) {
              exist = recursiveFunc(values[i].relations[j]);
            }
          }
        }
        return exist;
      };
      if (this.fileData) {
        exist = recursiveFunc(this.fileData);
      }
      return exist;
    }
  }
};
